import logoLink from '../resources/stwbo-netz.svg';

export const cssVariables = {
    active: '#404040',
    inactive: '#f4999f',

    primary_light: '#F7B7BC',
    primary: '#404040',
    primary_dark: '#202020',
    text_on_primary: '#fff',
    primary_transparent: '#EB4D57',

    secondary: '#E3000F',
    secondary_light: '#F7B7BC',
    text_on_secondary: '#fff',
    secondary_transparent: '#F7B7BC',

    font_color: 'black',
    link_color: '#00A0B9',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#404040',
    activate_color: '#C8C9C9',
    deactivate_color: '#C8C9C9',
    delete_color: '#E3000F',

    dropdown_background: '#F7B7BC',

};

export const logo = logoLink;
